*,
*::after,
*::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    --color-text: #fff;
    --color-bg: #FFF;
    --color-link: #4b4b4b;
    --color-link-hover: #fff;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
    content: '';
    position: fixed;
    z-index: 1000;
}

.js .loading::before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.js .loading::after {
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
    border-radius: 50%;
    opacity: 0.4;
    background: var(--color-link);
    animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
    to {
        opacity: 1;
        transform: scale3d(0.5, 0.5, 1);
    }
}

.js-image {
    max-width: 100%;
    display: block;
}

.loaded .js-image {
    opacity: 0;
}

canvas {
    display: inline-block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
}